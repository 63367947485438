import {
  CliniciansStore,
  ConstantsStore,
  LocationsStore,
  ProvidersStore,
  SettingsStore,
  TicketTypesStore
} from 'mobx/stores';

import { formatDate, LONG_DATE_FORMAT } from 'utils/DateUtils';

import {
  extractItemTypesFilterQuery,
  extractTicketFromQueryRequestParams
} from 'utils/serverFiltersUtils';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import { FEATURES } from 'constants/features';

import Patient from 'models/Patient';

import {
  DEFAULT_WQ_ASSIGNED_TO_ME_RECORDS_PER_PAGE,
  DEFAULT_WQ_RECORDS_PER_PAGE,
  TicketSortOrder
} from 'models/Settings';
import Ticket, { TicketClass, TicketStatus, ticketStatusToString } from 'models/Ticket';

import { SPECIAL_FILTERS, UNASSIGNED_TICKETS_OPTION } from 'views/Filters/filters.constants';
import { SearchFiltersType } from 'views/Filters/filters.types';

import {
  WorkQueueOpenItemsSectionName,
  WorkQueueRequestFilters,
  WorkQueueRequestSection,
  WorkQueueSectionFilters,
  WorkQueueSectionName
} from 'views/WorkQueue/WorkQueue.types';

import { getAssigneesOptionsForFilterSelect } from 'components/UIkit/atoms/Dropdown/SelectUtils';

export const buildServerSectionsOnFirstPageQuery = ({
  sections,
  ticketSortOrder,
  recordsPerPage = DEFAULT_WQ_RECORDS_PER_PAGE,
  assignedToMeRecordsPerPage = DEFAULT_WQ_ASSIGNED_TO_ME_RECORDS_PER_PAGE
}: {
  sections: Exclude<WorkQueueSectionName, WorkQueueSectionName.ResolvedItems>[];
  ticketSortOrder: TicketSortOrder;
  recordsPerPage: number;
  assignedToMeRecordsPerPage: number;
}): WorkQueueRequestSection[] =>
  sections.map((sectionName) =>
    buildServerSectionQuery({
      sectionName,
      pageNumber: 0,
      ticketSortOrder,
      recordsPerPage,
      assignedToMeRecordsPerPage
    })
  );

export const buildServerSectionQuery = ({
  sectionName,
  pageNumber,
  ticketSortOrder,
  recordsPerPage = DEFAULT_WQ_RECORDS_PER_PAGE,
  assignedToMeRecordsPerPage = DEFAULT_WQ_ASSIGNED_TO_ME_RECORDS_PER_PAGE,
  filters
}: {
  sectionName: WorkQueueSectionName;
  pageNumber: number;
  ticketSortOrder: TicketSortOrder;
  recordsPerPage: number;
  assignedToMeRecordsPerPage?: number;
  filters?: WorkQueueSectionFilters;
}): WorkQueueRequestSection => ({
  recordsPerPage:
    sectionName === WorkQueueSectionName.AssignedToMe ? assignedToMeRecordsPerPage : recordsPerPage,
  pageNumber,
  name: sectionName,
  sort: ticketSortOrder,
  filters
});

export const getSectionToFetchByType = (
  ticket: Ticket,
  patient?: Patient
): WorkQueueOpenItemsSectionName | null => {
  const isPatientReportTicket = ticket.class === TicketClass.patient;
  const isUrgentReportTicket = isPatientReportTicket && patient?.hasUrgentReports;
  const isOtherPatientReportTicket = isPatientReportTicket && !patient?.hasUrgentReports;

  if (ticket.isTask) {
    return WorkQueueSectionName.TasksDue;
  }

  if (ticket.isCallbackRequestTicket || ticket.isGeneralOperatorTicket) {
    return WorkQueueSectionName.TicketsAndCallbackRequests;
  }

  if (ticket.isSymptomOperatorTicket || isUrgentReportTicket) {
    return WorkQueueSectionName.UrgentPatientReports;
  }

  if (isOtherPatientReportTicket) {
    return WorkQueueSectionName.OtherPatientReports;
  }

  return null;
};

export const extractWqQueryFromFilters = (
  filters: SearchFiltersType,
  ticketTypesStore: TicketTypesStore
): Partial<WorkQueueRequestFilters> => {
  const { fromDate, toDate } = filters;
  return {
    // General
    nameOrMrn: filters.searchTerm,
    assignees: filters.assignees?.map((option) => option.value as number),
    providers: filters.providers?.map((option) => option.value?.id as string),
    locations: filters.locations?.map((option) => option.value as number),
    patientTags: filters.patientTags?.map((option) => option.value as number),
    // Tasks
    episodes: filters.episodeIds?.map((option) => option.value as number),
    roles: filters.role?.map((option) => option.value as number),
    owners: filters.owners?.map((option) => option.value as number),
    taskStatuses: filters.status?.map((option) => option.value as TicketStatus),
    taskSearchTerm: filters.taskSearchTerm,
    lastNameFirstLetter: filters.lastNameFirstLetter?.map((option) => option.value as string),
    fromDate: fromDate ? formatDate(fromDate, LONG_DATE_FORMAT) : null,
    toDate: toDate ? formatDate(toDate, LONG_DATE_FORMAT) : null,
    ...extractItemTypesFilterQuery(ticketTypesStore, filters.ticketType!)
  };
};

export const extractFiltersFromQuery = async (
  query: WorkQueueRequestFilters,
  cliniciansStore: CliniciansStore,
  constantsStore: ConstantsStore,
  locationsStore: LocationsStore,
  providersStore: ProvidersStore,
  settingsStore: SettingsStore
): Promise<SearchFiltersType> => {
  const assignees = getAssigneesOptionsForFilterSelect(
    await cliniciansStore.getCliniciansByIds(query.assignees ?? [])
  );
  const indexOfUnassigned = (query.assignees ?? []).indexOf(SPECIAL_FILTERS.UNASSIGNED);
  if (indexOfUnassigned !== -1) {
    assignees.splice(indexOfUnassigned, 0, UNASSIGNED_TICKETS_OPTION);
  }

  const providers = query.providers?.map((providerId) => {
    return providersStore.providersForSelect.find((p) => p.value.id === providerId);
  });

  const locations = query.locations?.map((locationId) => {
    if (settingsStore.hasFeature(FEATURES.REGIONS)) {
      return locationsStore.regionsForSelect.find((r) => r.value === locationId);
    }

    return locationsStore.locationsForSelect
      .map((locationOption) => ({
        label: locationOption.label,
        value: locationOption.value?.id
      }))
      .find((r) => r.value === locationId);
  });

  const patientTags = query.patientTags
    ? constantsStore.getTagsByIds(query.patientTags).map(({ id, name }) => ({
        value: id,
        label: name
      }))
    : [];

  const status = query.taskStatuses?.map((statusId) => ({
    value: statusId,
    label: ticketStatusToString[statusId]
  }));

  const role = query.roles?.map((roleId) => ({
    value: roleId,
    label: constantsStore.getRoleByIdWithAllAndNoRoles(roleId)?.name || 'Unknown'
  }));

  const owners = getAssigneesOptionsForFilterSelect(
    await cliniciansStore.getCliniciansByIds(query.owners ?? [])
  );

  const episodeIds = query.episodes?.reduce((acc, episodeId) => {
    const episode = constantsStore.episodeTemplatesMetadataMap.get(episodeId);
    if (episode) {
      acc.push({
        value: episodeId,
        label: episode?.name
      });
    }
    return acc;
  }, []);

  const lastNameFirstLetter = query.lastNameFirstLetter?.map((letter) => ({
    value: letter,
    label: letter
  }));

  const ticketType: TicketSubTypeOption[] | null = extractTicketFromQueryRequestParams(
    settingsStore,
    query
  );

  return {
    ticketType,
    nameOrMrn: query.nameOrMrn,
    searchTerm: query.nameOrMrn,
    taskSearchTerm: query.taskSearchTerm,
    fromDate: query.fromDate ? new Date(query.fromDate) : undefined,
    toDate: query.toDate ? new Date(query.toDate) : undefined,
    assignees,
    providers,
    locations,
    patientTags,
    status,
    role,
    owners,
    episodeIds,
    lastNameFirstLetter
  };
};
